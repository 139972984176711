<template>
  <div>
    <div class="page-title" v-if="task">
      <h3 class="valign-wrapper">
        Просмотр отчета для заявки {{ task_name }}
        <i class="small material-icons" v-tooltip="task.title">info</i>
      </h3>
      <span>
        <a class="btn-floating btn-large waves-effect waves-light red"
           v-tooltip="'Перейти к заявке'"
           @click="$router.push('/tasks/' + task_id)">
          <i class="material-icons">arrow_back</i>
        </a>
      </span>
    </div>

    <Loader v-if="loading"/>

    <section v-else>
      <div class="row">
        <div class="col s4">
          <div class="input-field">
            <input id="reporter" type="text" v-model="reporter_name" disabled>
            <label for="reporter">Добавил</label>
          </div>
        </div>
        <div class="col s4">
          <div class="input-field">
            <input id="equipment" type="text" v-model="equipment" disabled>
            <label for="equipment">Тип оборудования</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s4">
          <div class="input-field">
            <textarea id="description" class="materialize-textarea" v-model="description" disabled></textarea>
            <label for="description">Описание работы</label>
          </div>
        </div>
        <div class="col s4">
          <div class="input-field">
            <input id="inventory_number" type="text" v-model="inventory_number" disabled>
            <label for="inventory_number">Инвентарный номер</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <h5>Фотографии (загружено {{uploaded_photo_count}} из {{photo_count}})</h5>
          <section v-if="photos">
            <div class="row">
              <div class="col s6">
                <a class="btn waves-effect waves-light blue darken-4" @click="download">Загрузить</a>
              </div>>
            </div>
          </section>
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          <section v-if="photos">
            <div class="images" v-viewer>
              <img v-for="src in photos" :key="src" :src="src" class="photo_preview">
            </div>
          </section>
          <section v-else>Отсутствуют</section>
        </div>
      </div>

      <div class="row" v-if="task_status === 'completed'">
        <div class="col s4 l1">
          <a class="btn waves-effect waves-light blue darken-4" @click="submit">Принять</a>
        </div>
        <div class="col s4 l2">
          <a class="btn waves-effect waves-light red darken-4" @click="decline">Создать ППР</a>
        </div>
      </div>
      <div class="row" v-else-if="task_status === 'verifying'">
        <div class="col s1">
          <a class="btn waves-effect waves-light green darken-4" @click="closeTask">Принять акт</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import dateFilter from "@/filters/date.filter";

export default {
  metaInfo() {
    return {
      title: 'Просмотр отчета'
    }
  },
  data: () => ({
    loading: true,
    task_id: '',
    task: null,
    task_status: '',
    reporter: '',
    team_name: '',
    equipment: '',
    inventory_number: '',
    description: '',
    created_at: '',
    uploaded_photo_count: 0,
    photo_count: 0,
    photos: [],
    carousel: null,
  }),
  async mounted() {
    const report = await this.$store.dispatch('fetchTaskReport', this.$route.params.id)
    this.task_id = report.task.id
    this.task = report.task
    this.task_status = report.task.status
    this.reporter = report.reporter.last_name + ' ' + report.reporter.first_name
    this.equipment = report.equipment_type ? report.equipment_type.name : ''
    this.inventory_number = report.inventory_number
    this.description = report.description
    this.created_at = report.created_at
    this.photo_count = report.photo_count
    this.uploaded_photo_count = report.photos ? report.photos.length : 0
    this.photos = report.photos

    if (report.team) {
      this.team_name = report.team.name
    }

    this.loading = false

    setTimeout(() => {
      this.carousel = M.Materialbox.init(
        document.querySelectorAll('.materialboxed'),
      );
      M.textareaAutoResize(document.getElementById('description'))
      M.updateTextFields()
    })
  },
  computed: {
    task_name: function (){
      return this.getTaskName()
    },
    reporter_name: function (){
      return this.reporter + ' (' +this.team_name + ')'
    },
  },
  methods: {
    async submit() {
      const t = await this.$store.dispatch('submitReport', this.task_id)
      await this.$router.push('/tasks')
    },
    async decline() {
      const t = await this.$store.dispatch('declineReport', this.task_id)
      await this.$router.push('/tasks/' + t.id)
    },
    async closeTask() {
      await this.$store.dispatch('closeTask', this.task_id)
      await this.$router.push('/tasks')
    },
    async download() {
      const file = await this.$store.dispatch('downloadReport', this.task_id)
      let fileName = this.getObjectName()

      if (this.equipment !== '') {
        fileName += ' ' + this.equipment
      }

      let date = dateFilter(this.created_at.seconds * 1000, 'datetime')
      date = date.replace(',', '')
      date = date.replaceAll(':', ' ')

      fileName += ' ' + this.reporter
      fileName += ' ' + date
      fileName += '.zip'

      saveAs(
        new Blob([file], { type: `application/x-zip-compressed` }),
        fileName,
      );
    },
    getTaskName() {
      if (!this.task) {
        return ""
      }

      if (this.task.external_id) {
        return this.task.external_id + ' (GSI ' + this.task.id + ')'
      } else {
        return this.task.id
      }
    },
    getObjectName() {
      if (!this.task || !this.task.object) {
        return ""
      }

      if (this.task.object.external_id) {
        return this.task.object.external_id + ' (GSI ' + this.task.object.id + ')'
      } else {
        return this.task.object.id
      }
    },
  },
}
</script>

<style scoped>
.switch {
  margin-top: 1rem;
}
.photo_preview {
  height: 300px;
  margin-right: 20px;
}
</style>
